import { EventBus } from "@/state/state";
import { store } from "@/state/state";

const baseUrl = "https://reverberate-demo.webapp.lzeid.de/api/v1";
let token = "";

export function setToken(newToken: string) {
  token = newToken;
}
export function clearToken() {
  token = "";
}

// CRUD Requests
export async function performCRUDRequest<T>(params: RequestParams): Promise<T> {
  try {
    const { method, endpoint, UUID, data } = params;
    let url = baseUrl + endpoint;
    if (UUID) url += `/${UUID}`;

    const response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json" // Set the Content-Type header
      },
      body: data ? JSON.stringify(data) : undefined
    });

    if (response.status === 403) {
      store.invalidUser = true;
      throw null; // throw null so user doesnt see error from backend
    }

    if (!response.ok) {
      throw new Error("Request failed with status: " + response.status);
    }
    const responseData = await response.json();
    if (!responseData.success)
      throw new Error("ERROR:: " + responseData.message);
    return responseData.data;
  } catch (error) {
    if (error instanceof Error) EventBus.$emit("prompt-error", error.message);
    throw error;
  }
}

export enum Endpoint {
  UserData = "/user-data",
  Material = "/material",
  Scattering = "/scattering",
  Furniture = "/furniture",
  Project = "/project",
  Results = "/results"
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE"
}

/**
 * Request parameters for performing CRUD operations.
 * - method: HttpMethod,
 * - endpoint: Endpoint,
 * - UUID?: string,
 * - data?: Object;
 */
export interface RequestParams {
  /** The HTTP method for the request.*/
  method: HttpMethod;
  /** The API endpoint for the request. */
  endpoint: Endpoint;
  /** The UUID of the resource (optional). */
  UUID?: string;
  /** The data Object (material, scattering, furniture, project) payload (requestBody) for the request (optional).*/
  data?: Object;
}
