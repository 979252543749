import { clearAllUserData, getAllUserData } from "@/res/user-data";
import router from "@/router/routes";
import { clearToken, setToken } from "@/services/userServices";
import { store } from "./state";

function start(): void {
  if (store.invalidUser) {
    router.push("/paywall");
  } else {
    router.push("/home");
  }
}
// Azure AD B2C
// OpenID Connect (OIDC) Authorization Code Flow with PKCE (Proof Key for Code Exchange), see RFC 7636, https://tools.ietf.org/html/rfc7636

// init vars
const baseURL =
  "https://lzeiddevel.b2clogin.com/lzeiddevel.de/b2c_1a_auth0_test_signup_signin/oauth2/v2.0/";
/* eslint-disable @typescript-eslint/no-unused-vars */
const authorizeEndpoint = "authorize"; // The authorization endpoint of the IDP
const logoutEndpoint = "logout";
const tokenEndpoint = "token";
const client_id = "61ed316b-7448-4c08-a6f8-7efe17fb7b44";
const scope = "openid";
const response_type = "code";
const redirect_uri = window.location.href.split("#")[0]; // "https://reverberate-test-frontend.webapp.lzeid.de/" "http://localhost:8080";
const response_mode = "fragment";
const nonce = "MyNonce";
const code_challenge_method = "S256";

//
export async function init() {
  //sessionStorage.getItem("accessToken") ? showToken() : showButton();

  const hashParams = new URLSearchParams(window.location.hash.substring(1));
  const authCode = hashParams.get("/code");
  //const cleanURLWithoutHash = window.location.href.split("#")[0]; // Remove hash part from URL
  //history.replaceState({}, document.title, cleanURLWithoutHash); // Update URL without query and hash
  history.replaceState({}, document.title, "#/"); // Update URL without query and hash
  if (authCode) await requestToken(authCode);
}

export async function getToken(authCode: string) {
  history.replaceState({}, document.title, "#/"); // Update URL without query and hash
  if (authCode) await requestToken(authCode);
}

// Request Token with verification code and code verifier
async function requestToken(authCode: string) {
  const verifier = sessionStorage.getItem("verifier");
  if (!verifier) {
    login();
    return;
  }
  //showTokenRequest();
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", client_id);
  params.append("code_verifier", verifier);
  params.append("redirect_uri", redirect_uri); // http://localhost:5500 doesn NOT work
  params.append("code", authCode);
  fetch(`${baseURL}${tokenEndpoint}?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
    // credentials: "omit", // not include any cookies or HTTP authentication credentials in the request or store any cookies from the response
    // mode: "cors",
  })
    .then(response => {
      return response.json();
    })
    .then(async tokenData => {
      // Handle the token response
      sessionStorage.removeItem("verifier");
      setExpirationData(
        tokenData.id_token_expires_in,
        tokenData.refresh_token_expires_in
      );
      sessionStorage.setItem("accessToken", tokenData.id_token);
      sessionStorage.setItem("refreshToken", tokenData.refresh_token);
      await establishSession();
      // setToken(tokenData.id_token);
      // await getAllUserData();
      // activateSession();
      // start();
      //showToken();
    })
    .catch(error => {
      // Handle the error
      console.error(error);
    });
}

export async function establishSession() {
  const token = sessionStorage.getItem("accessToken");
  if (!token) return false;
  try {
    setToken(token);
    // Trying to get Userdata from user
    try {
      await getAllUserData();
      store.invalidUser = false;
    } catch (error) {
      console.error("Failed to fetch userdata", error);
      store.invalidUser = true;
    }
    if (!store.invalidUser) {
      getAllUserData();
      activateSession();
      start();
    } else {
      sessionActive = true;
      start();
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

// Expiration Code:
let exp_time = 0;
function setExpirationData(exp_access: number, exp_refresh: number) {
  const exp_time = Math.floor(exp_access + Date.now() / 1000);
  sessionStorage.setItem("exp_access", exp_time.toString());
  //startTimer();
}

// Login to IDP and request authorization code
export async function login() {
  const verifier = generateRandomVerifier(64);
  sessionStorage.setItem("verifier", verifier);
  const code_challenge = await generateCodeChallenge(verifier);
  const authorizationURL = `${baseURL}${authorizeEndpoint}?client_id=${client_id}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirect_uri}&response_mode=${response_mode}&nonce=${nonce}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}`;
  window.location.href = authorizationURL;
}

// Logout from IDP and remove tokens from session storage
export async function logout() {
  clearToken();
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("exp_access");
  sessionStorage.removeItem("exp_refresh");
  deactivateSession();
  clearAllUserData();
  const logoutURL = `${baseURL}${logoutEndpoint}?client_id=${client_id}&post_logout_redirect_uri=${redirect_uri}`;
  window.location.href = logoutURL;
}

// Generate random code verifier (PKCE, see RFC 7636: random string with a minimum length of 43 characters and a maximum length of 128 characters)
function generateRandomVerifier(length: number) {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  let verifier = "";
  for (let i = 0; i < length; i++) {
    verifier += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return verifier;
}

// Generate code challenge from code verifier
async function generateCodeChallenge(verifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const challenge = btoa(String.fromCharCode(...new Uint8Array(hash)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
  return challenge;
}

export function getRemainingTime(): number {
  let exp_access = sessionStorage.getItem("exp_access");
  if (!exp_access || isNaN(Number(exp_access))) return 0;
  const currentTime = Math.floor(Date.now() / 1000);
  let exp_time = parseInt(exp_access || currentTime.toString());
  const remainingTime = exp_time - Math.floor(Date.now() / 1000);
  if (remainingTime <= 0) logout();
  return remainingTime;
}

let sessionActive: boolean = false;
export function isSessionActive(): boolean {
  return sessionActive;
}

/* Prompt consent before leaving website during a session */
export function activateSession(): void {
  window.onbeforeunload = function () {
    return "Do you really want to leave reverberate pro?";
  };
  document.addEventListener("mousedown", refreshToken);
  sessionActive = true;
}
export function deactivateSession(): void {
  window.onbeforeunload = function () {
    // nothing
  };
  document.removeEventListener("mousedown", refreshToken);
  sessionActive = false;
}

// Refresh Token with refresh token
async function refreshToken() {
  if (getRemainingTime() > 1800) return; // Do not refresh if Session Length > 30 min
  const refresh_token = sessionStorage.getItem("refreshToken");
  if (!refresh_token) {
    logout();
    return;
  }
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("client_id", client_id);
  params.append("refresh_token", refresh_token || "");
  params.append("redirect_uri", redirect_uri);
  fetch(`${baseURL}${tokenEndpoint}?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    credentials: "omit",
    mode: "cors"
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        logout();
      }
    })
    .then(tokenData => {
      setExpirationData(
        tokenData.id_token_expires_in,
        tokenData.refresh_token_expires_in
      );
      setToken(tokenData.id_token);
      sessionStorage.setItem("accessToken", tokenData.id_token);
      sessionStorage.setItem("refreshToken", tokenData.refresh_token);
    })
    .catch(error => {
      logout();
    });
}
