export const EventBus = new Vue();
export const SharedFunctions: any = { promptConfirmation: null };

import { Atmosphere } from "@/calc/acoustic-constants";
import {
  dimensionalAverageAbsorption,
  dimensionalAverageScattering,
  generateFurniture,
  generateInterestingRoom,
  WallConfiguration
} from "@/calc/area-assignment";
import { DecayFunction } from "@/calc/decay-time";
import { relativeSoundPressureLevel } from "@/calc/decibel";
import {
  createExampleAtmosphere,
  default_Lx,
  default_Ly,
  default_Lz
} from "@/calc/default-inputs";
import { DIN_SCENARIOS } from "@/calc/din-requirements";
import {
  getPerspective,
  OctoFrequential,
  RoomDirectionMultiFreq,
  RoomDirectionSingleFreq,
  RoomSingleFreq,
  TriDimensional
} from "@/calc/room";
import { diffuseSquaredSoundPressure } from "@/calc/sound-pressure-diffuse";
import { directionalSquaredSoundPressure } from "@/calc/sound-pressure-directional";
import {
  DetailedResult,
  summedSquaredSoundPressure
} from "@/calc/sound-pressure-summed";
import Vue from "vue";

/** Contains all user input needed for calculation */
export interface FurnitureConfiguration {
  quantity: number;
  uuid: string;
  position: number;
}
export interface CalculationState {
  atmosphere: Atmosphere;
  roomSize: TriDimensional<number>;
  dinScenario: DIN_SCENARIOS;
  roomWalls: [
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration
  ];
  furniture: FurnitureConfiguration[];
  initialFurnishingOption: boolean;
  roomName: string;
}

export interface AppState {
  calc: CalculationState;
  result: DetailedResult | null;
  error: Error | null;
}

const state: AppState = {
  calc: {
    atmosphere: createExampleAtmosphere(),
    roomSize: [default_Lx, default_Ly, default_Lz],
    dinScenario: DIN_SCENARIOS.A1,
    roomWalls: generateInterestingRoom(),
    furniture: generateFurniture(),
    initialFurnishingOption: false,
    roomName: ""
  },
  result: null,
  error: null
};

/**
 * This app is not yet using Vuex as state management engine. Instead it uses
 * a custom store implementation that is in fact a Vue instance similar to a
 * Vue component, but without template.
 * All modifications to the state inside the store are always made in a
 * immutable fashion. This allows e.g. the Calculation.vue component to create
 * a local copy of the state for the latest changes and commit it only when the
 * user clicks "simulate/calculate".
 * State management is work in progress.
 */
class Store extends Vue {
  private state: AppState = state;
  public roomUUID: string | null = null;
  public roomName: string = "";
  public roomDescription: string = "";
  public walls_polygonsZSorted: any;
  public walls_sizes: any;
  public walls_specs = { xMax: 0, yMax: 0 };
  public loading: boolean = false;
  public simulatedButtonPressed: boolean = false;
  public isASR: boolean = false;
  public detailedReport: boolean = false;
  public tradeMark = "®";
  public invalidUser = false;
  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageAbsorption(
      this.state.calc.roomWalls,
      this.state.calc.roomSize,
      this.state.calc.furniture
    );
  }
  // get A_o() {
  //   return get_Ao(this.state.calc.furniture);
  // }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageScattering(
      this.state.calc.roomWalls,
      this.state.calc.roomSize
    );
  }
  get roomMultiFreq(): TriDimensional<RoomDirectionMultiFreq> {
    return this.state.calc.roomSize.map((L, dIdx) => ({
      L,
      alpha: this.dimensionalAbsorption[dIdx],
      sc: this.dimensionalScattering[dIdx]
    })) as TriDimensional<RoomDirectionMultiFreq>;
  }

  get result(): AppState["result"] {
    return this.state.result;
  }

  get calc(): AppState["calc"] {
    return this.state.calc;
  }

  get error(): AppState["error"] {
    return this.state.error;
  }

  async calculate() {
    this.loading = true;
    return;
    this.state.result = summedSquaredSoundPressure(
      this.state.calc.atmosphere,
      this.roomMultiFreq
    );
  }
  setResult(result: DetailedResult) {
    this.state.result = this.loadResults(
      result,
      this.state.calc.atmosphere,
      this.roomMultiFreq
    );
  }

  loadResults(
    res: DetailedResult,
    atmosphere: Atmosphere,
    room: TriDimensional<RoomDirectionMultiFreq>
  ) {
    this.addSPL_dB_relative(res, atmosphere, room);
    return res as DetailedResult;
  }

  addSPL_dB_relative(
    res: any,
    atmosphere: Atmosphere,
    room: TriDimensional<RoomDirectionMultiFreq>
  ) {
    const octoFrequential: Array<any> = res.octoFrequential;
    octoFrequential.forEach((element, fIdx) => {
      const room_f = room.map<RoomDirectionSingleFreq>((d: any) => ({
        L: d.L,
        alpha: d.alpha[fIdx],
        sc: d.sc[fIdx]
      })) as RoomSingleFreq;
      const P2_sum0 = element.P2_sum0;
      const P2_s_t: DecayFunction = diffuseSquaredSoundPressure(
        element.P2_s0,
        res.c_0,
        res.L_s,
        element.m_f,
        element.alpha_s
      );
      element.triDimensional.forEach((tri: any, idx: any) => {
        const perspective = getPerspective(room_f, idx);
        const { frontal } = perspective;
        const L_i = frontal.L;
        const P2_i_t: DecayFunction = directionalSquaredSoundPressure(
          L_i,
          res.c_0,
          tri.P2_i0,
          tri.mL_i,
          tri.BR_ie,
          tri.alpha_ie,
          tri.SC_ie
        );
        tri.P2_i_t = P2_i_t;
      });
      const P2_sum_t: DecayFunction = time =>
        element.triDimensional[0].P2_i_t(time) +
        element.triDimensional[1].P2_i_t(time) +
        element.triDimensional[2].P2_i_t(time) +
        P2_s_t(time);
      const SPL_dB_relative: DecayFunction = (time: number) => {
        return relativeSoundPressureLevel(P2_sum_t(time), P2_sum0);
      };
      element.SPL_dB_relative = SPL_dB_relative;
    });
  }

  resetCalculation() {
    this.roomUUID = null;
    this.roomName = "";
    this.roomDescription = "not implemented (todo)";
    this.state = {
      calc: {
        atmosphere: createExampleAtmosphere(),
        roomSize: [default_Lx, default_Ly, default_Lz],
        dinScenario: DIN_SCENARIOS.A1,
        roomWalls: generateInterestingRoom(),
        furniture: generateFurniture(),
        initialFurnishingOption: false,
        roomName: ""
      },
      result: null,
      error: null
    };
  }

  setProject(object: CalculationState) {
    this.setCalc(object);
  }

  setError(error: Error) {
    this.state = { ...this.state, error };
  }
  setCalc(calc: CalculationState) {
    this.state = { ...this.state, calc };
  }
}

/**
 * Singleton instance of the store that can be imported
 * into components that need access to the central state in store.
 */
export const store = new Store();

/* 
// In the future maybe use Vuex plugin for store:
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
 */
