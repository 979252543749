



































































































































import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { Ripple } from "vuetify/lib/directives";
import MaterialInput from "@/components/MaterialInput.vue";
import {
  AreaAssignment,
  autoDivideRemainingArea
} from "@/calc/area-assignment";
import ExplanationHint from "@/components/ExplanationHint.vue";

@Component({
  components: { MaterialInput, ExplanationHint },
  directives: { Ripple }
})
export default class AreaAssignEditor extends Vue {
  /** Don't mutate array/object directly, but use immutable operations and emit new value with "change" event! */
  @Model("input", { type: Array })
  private value!: AreaAssignment[];

  get assignments(): AreaAssignment[] {
    return this.value;
  }

  /* Immutable update assignment at index */
  updateAssignment(aIdx: number, assignment: AreaAssignment) {
    const newAssignments: AreaAssignment[] = [...this.value]; // immutable copy
    newAssignments[aIdx] = assignment;
    this.$emit("input", newAssignments);
  }

  @Prop({ type: String })
  title!: string;

  @Prop({ type: Number })
  totalArea!: number;

  get debug(): string {
    return JSON.stringify(this.assignments);
  }

  get completedAreas(): number[] {
    return autoDivideRemainingArea(this.assignments, this.totalArea);
  }

  /** Immutable operation! */
  changeAuto(assignmentIndex: number, checkboxstate: any) {
    if (!checkboxstate && this.numberOfAutoAssignments == 1) {
      // the last *auto* assignment can't be removed
      return;
    }
    const newAssignment = { ...this.assignments[assignmentIndex] }; // immutable copy
    if (checkboxstate) {
      // if auto is checked
      newAssignment.area = null;
    } else {
      // initialize with prior *auto* value:
      newAssignment.area = this.completedAreas[assignmentIndex];
    }
    this.updateAssignment(assignmentIndex, newAssignment);
    this.$forceUpdate(); // otherwise the change wouldn't cycle back to the <input>'s value
  }
  /** Immutable operation! */
  changeAbsorptionMaterial(aIdx: number, mat: any) {
    const newAssignment = { ...this.assignments[aIdx], mat }; // immutable copy
    this.updateAssignment(aIdx, newAssignment);
  }
  /** Immutable operation! */
  changeScatteringMaterial(aIdx: number, matSc: any) {
    const newAssignment = { ...this.assignments[aIdx], matSc }; // immutable copy
    this.updateAssignment(aIdx, newAssignment);
  }
  /** Immutable operation! */
  changeArea(aIdx: number, event: any) {
    const newArea = event;
    const remainingUnassignedArea = this.assignments
      .filter((a, i) => i !== aIdx && a.area !== null)
      .reduce<number>(
        (remaining, curr) => remaining - (curr.area as number),
        this.totalArea
      );
    const newAssignment = { ...this.assignments[aIdx] }; // immutable copy
    newAssignment.area = Math.min(newArea, remainingUnassignedArea);
    this.updateAssignment(aIdx, newAssignment);
    this.$forceUpdate(); // otherwise the change wouldn't cycle back to the <input>'s value
  }
  addAssignment() {
    const prevAssignment = this.assignments[this.assignments.length - 1];
    const mat = prevAssignment.mat;
    const matSc = prevAssignment.matSc;
    const newAssignment = { area: null, mat, matSc };
    this.$emit("input", [...this.value, newAssignment]); // immutable addition
  }
  deleteAssignment(idx: number) {
    const newAssignments = [...this.assignments]; // immutable copy
    newAssignments.splice(idx, 1);
    this.$emit("input", newAssignments);
    this.correctAssignment();
  }
  get numberOfAutoAssignments(): number {
    return this.assignments.filter(a => a.area === null).length;
  }
  correctAssignment() {
    if (this.numberOfAutoAssignments === 0) {
      this.addAssignment();
    }
  }
  /** Says for each assignments if it's the last *auto* and therefore should stay *auto*. */
  get isTheLastAuto(): boolean[] {
    return this.assignments.map(
      a => a.area === null && this.numberOfAutoAssignments === 1
    );
  }
}
